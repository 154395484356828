import * as R from 'ramda';
import React from 'react';
import { compose, withHandlers } from 'react-recompose';
// features
import { makeCloTotalData } from '../features/dispatch-details-new/helpers'
import { AsyncEditCustomerInvoiceForm } from '../features/invoice/components/customer-form';
// helpers/constants
import * as G from '../helpers';
import * as GC from '../constants';
// utilities
import endpointsMap from '../utilities/endpoints';
//////////////////////////////////////////////////

const getBranchGuid = (props: Object, invoice: any, load: any) => {
  const { branchGuid } = props;

  const invoiceBranchGuid = G.getBranchGuidFromObject(invoice);

  const loadBranchGuid = G.getBranchGuidFromObject(load);

  const cloBranchGuid = R.pathOr(
    R.path([GC.FIELD_CLO, GC.FIELD_BRANCH_GUID], props),
    [GC.FIELD_CLO, GC.FIELD_BRANCH, GC.FIELD_GUID],
    props,
  );

  return R.or(
    R.or(cloBranchGuid, invoiceBranchGuid),
    R.or(loadBranchGuid, branchGuid),
  );
};

const getItemsVolume = (props: Object) => {
  const { itemsVolume, cloItemsVolume } = props;

  return R.or(itemsVolume, cloItemsVolume);
};

const getStopsQuantity = (props: Object, page: string) => {
  const { load } = props;

  if (R.equals(page, GC.PAGE_DISPATCH_DETAILS_NEW_LOAD)) {
    const { entity } = props;

    const { cloGuid } = entity;

    const clo = R.find(R.pathEq(cloGuid, [GC.FIELD_GUID]), R.prop(GC.FIELD_CLOS, load));

    const { cloEvents } = makeCloTotalData(clo, load);

    return R.length(cloEvents);
  }

  return R.length(R.pathOr([], [GC.FIELD_LOAD_STOPS], load));
};

export const withAsyncEditCloInvoice = (options: any) => compose(
  withHandlers({
    handleUpdateCloInvoice: (props: Object) => (values: Object, callback: Function) => {
      const { updateCloInvoiceRequest, updateLoadCustomerInvoiceRequest, updateOrderCustomerInvoiceRequest } = props;

      const updateFrom = R.path(['updateFrom'], options);

      if (R.and(
        R.equals(updateFrom, GC.PAGE_DISPATCH_DETAILS_NEW_ORDER),
        G.isFunction(updateOrderCustomerInvoiceRequest),
      )) {
        return updateOrderCustomerInvoiceRequest(values);
      }

      if (R.and(
        R.equals(updateFrom, GC.PAGE_DISPATCH_DETAILS_NEW_LOAD),
        G.isFunction(updateLoadCustomerInvoiceRequest),
      )) {
        return updateLoadCustomerInvoiceRequest(values);
      }

      const data = {
        callback,
        updateFrom,
        invoice: values,
      };

      updateCloInvoiceRequest(data);
    },
  }),
  withHandlers({
    handleEditCloInvoice: (props: Object) => (invoice: Object, callback: Function) => {
      const { load, openModal, handleUpdateCloInvoice } = props;

      const updateFrom = R.path(['updateFrom'], options);

      const endpoint = endpointsMap.getCloInvoiceItemEndpoint(G.getGuidFromObject(invoice));

      const itemsVolume = getItemsVolume(props);
      const branchGuid = getBranchGuid(props, invoice, load);
      const stopsQuantity = getStopsQuantity(props, updateFrom);

      const modalContent = (
        <AsyncEditCustomerInvoiceForm
          branchGuid={branchGuid}
          initialValues={invoice}
          asyncEndpoint={endpoint}
          itemsVolume={itemsVolume}
          stopsQuantity={stopsQuantity}
          cloGuid={G.getPropFromObject(GC.FIELD_CLO_GUID, invoice)}
          handleSendCloInvoice={(values: Obect) => handleUpdateCloInvoice(values, callback)}
        />
      );

      const modal = {
        component: modalContent,
        options: {
          width: 900,
          height: 'auto',
          overflow: 'auto',
          maxHeight: '90vh',
        },
        p: '0',
      };

      openModal(modal);
    },
  }),
);
