import * as R from 'ramda';
import { useState } from 'react';
// helpers/constants
import * as G from '../helpers';
// utilities
import { sendRequest } from '../utilities/http';
import endpointsMap from '../utilities/endpoints';
//////////////////////////////////////////////////

// NOTE: available types
// LOAD, MAINTENANCE, EXPENSE

const useAiRecognize = (params: any) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const type = R.pathOr('LOAD', ['type'], params);

  const aiRecognizeRequest = async (file: Object, callback: Function) => {
    try {
      setLoading(true);

      const reqData = G.makeDataForDocument({ file });

      const options = {
        data: reqData,
        params: { type },
      };

      const res = await sendRequest('post', endpointsMap.aiDocumentRecognize, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        setData(data);

        G.callFunctionWithArgs(callback, data);
      } else {
        await G.handleFailResponseSimple(res, 'useAiRecognize/aiRecognizeRequest fail');
      }
    } catch (error) {
      await G.showToastrMessageSimple('error', 'messages:error:unknown');
      await G.handleException(error, 'useAiRecognize/aiRecognizeRequest exception');
    } finally {
      setLoading(false);
    }
  };

  return {
    aiRecognizeRequest,
    aiRecognizeData: data,
    aiRecognizeLoading: loading,
  };
};

export {
  useAiRecognize,
};
