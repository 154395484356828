import * as R from 'ramda';
import { pure, compose, lifecycle, withState, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../helpers';
import * as GC from '../constants';
// utilities
import { sendRequest } from '../utilities/http';
import endpointsMap from '../utilities/endpoints';
//////////////////////////////////////////////////

export const withLoadCustomStatusOptions = compose(
  withState('loadCustomStatusOptions', 'setLoadCustomStatusOptions', R.pathOr([], ['loadCustomStatuses'])),
  withHandlers({
    handleGetLoadCustomStatusOptions: (props: Object) => async () => {
      const { loadType, setLoadCustomStatusOptions } = props;

      const options = {
        params: { loadType, [GC.BRANCH_GUID]: G.getCurrentBranchGuid() },
      };

      const res = await sendRequest('get', endpointsMap.loadCustomStatusListAvailable, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        const options = R.map(
          ({ guid, name }: Object) => ({
            guid,
            name,
            [GC.FIELD_VALUE]: name,
            [GC.FIELD_LABEL]: name,
          }),
          R.or(data, []),
        );

        setLoadCustomStatusOptions(options);
      } else {
        G.handleException('error', 'withLoadCustomStatusOptions exception');
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      const { loadCustomStatusOptions, handleGetLoadCustomStatusOptions } = this.props;

      if (G.isNilOrEmpty(loadCustomStatusOptions)) handleGetLoadCustomStatusOptions();
    },
  }),
  pure,
);
