import * as R from 'ramda';
import moment from 'moment';
// constants
import * as GC from '../constants';
// helpers
import { toNumber } from './calc';
import { isPlural } from './string';
import { getAmousConfigByNameFromWindow } from './window';
import {
  ifElse,
  isNumber,
  isString,
  isAllTrue,
  notEquals,
  isNilOrZero,
  isNilOrEmpty,
  isNotNilAndNotEmpty,
} from './helpers';
//////////////////////////////////////////////////

const getCurrentDate = () => moment();

const getCurrentDay = () => moment().format(GC.DEFAULT_DATE_FORMAT);

const getCurrentDayWithTime = () => moment().format(GC.DEFAULT_DATE_TIME_FORMAT);

const getNewDate = (item: any) => new Date(item);

const makeMomentInstance = (item: any) => moment(item);

const isMomentInstance = (item: any) => moment.isMoment(item);

const getCurrentDateWithFormat = (format: string) => moment().format(format);

const isValidMoment = (item: string) => moment(item).isValid();

const isValidMomentWithFormat = (item: string, format: string) => moment(item, format, true).isValid();

const isValidTime = (time: any) => GC.TIME_REGEXP.test(time);

const getNowTimeMomentObject = () => moment().hour(0).minute(0);

const momentAddYearsFromCurrent = (years: number) => moment().add(years, 'years');

const momentSubtractYearsFromCurrent = (years: number) => moment().subtract(years, 'years');

const getDateRange = (firstDate: string, secondDate: string, format: string = 'd') => (
  moment(secondDate).diff(moment(firstDate), format)
);

const isSameMoment = (firstDate: string, secondDate: string, param: string) => {
  if (isNilOrEmpty(param)) return moment(firstDate).isSame(secondDate);

  return moment(firstDate).isSame(secondDate, param);
};
// year month week isoWeek day hour minute second - available param

const isAfter = (firstDate: string, secondDate: string, param: string) => {
  if (isNilOrEmpty(param)) return moment(firstDate).isAfter(secondDate);

  return moment(firstDate).isAfter(secondDate, param);
};

const isBefore = (firstDate: string, secondDate: string, param: string) => {
  if (isNilOrEmpty(param)) return moment(firstDate).isBefore(secondDate);

  return moment(firstDate).isBefore(secondDate, param);
};

const isBetween = (date: string, firstDate: string, secondDate: string, inclusion: string) =>
  moment(date).isBetween(firstDate, secondDate, undefined, R.or(inclusion, '()'));
// '()' - default exclusive
// '(]' - right inclusive
// '[)' - left inclusive
// '[]' - all inclusive

const isBetweenDay = (date: string, firstDate: string, secondDate: string, inclusion: string) =>
  moment(date).isBetween(firstDate, secondDate, 'day', R.or(inclusion, '[]'));

const toMidday = (date: string) => moment(date).set({ hour: 12, minute: 0, second: 0 });

const isMidnight = (date: string) => {
  const dateTime = moment(date).format('YYYY-MM-DD HH:mm:ss');

  const dateTimeInstance = moment(dateTime);

  return R.and(R.equals(dateTimeInstance.hour(), 0), R.equals(dateTimeInstance.minute(), 0));
};

const fromNow = (item: string) => moment(item).fromNow();

const getStartOfMonthDay = () => moment().startOf('month').format(GC.DEFAULT_DATE_FORMAT);

const addMomentTime = (item: interval, interval: number = 0, type: string = 'hours') => {
  if (isValidMoment(item)) return moment(item).add(interval, type);

  return item;
};

const subtractMomentTime = (item: interval, interval: number = 0, type: string = 'hours') => {
  if (isValidMoment(item)) return moment(item).subtract(interval, type);

  return item;
};

const addMomentTimeWithFormat = (
  item: interval,
  interval: number = 0,
  type: string = 'hours',
  format: string = GC.DEFAULT_DATE_TIME_FORMAT,
) => {
  if (isValidMoment(item)) return addMomentTime(item, interval, type).format(format);

  return item;
};

const subtractMomentTimeWithFormat = (
  item: interval,
  interval: number = 0,
  type: string = 'hours',
  format: string = GC.DEFAULT_DATE_TIME_FORMAT,
) => {
  if (isValidMoment(item)) return moment(item).subtract(interval, type).format(format);

  return item;
};

const convertInstanceToLocalTime = (item: Object) => moment(item).format('LT');

const convertInstanceToISOString = (item: Object) => moment(item).format();

const convertISOStringToLocalTime = (item: string) => moment(item).format('LT');

const createLocalDateTimeFormat = (dateFormat: string) => `${dateFormat} LT`;

const convertInstanceToDefaultDateFormat = (item: Object) => moment(item).format(GC.DEFAULT_DATE_FORMAT);

const convertInstanceToDefaultUserFormat = (item: Object) => moment(item).format(GC.DEFAULT_USER_FORMAT);

const convertInstanceToDefaultDateTimeFormat = (item: Object) => moment(item).format(GC.DEFAULT_DATE_TIME_FORMAT);

const createTodayLocalDateTimeString = (dateFormat: string, timeValue: string) => (
  `${moment().format(dateFormat)} ${timeValue}`
);

const createLocalDateTimeString = (ISOString: string, format: string) => (
  moment(ISOString).format(format)
);

const createLocalDateTimeFromInstanceOrISOString = (item: any, format: string) => (
  moment(item).format(format)
);

const toEndOfDayFromDate = (item: any) => (
  moment(item).endOf('day').format(GC.DEFAULT_DATE_TIME_FORMAT)
);

const toStartOfDayFromDate = (item: any) => (
  moment(item).startOf('day').format(GC.DEFAULT_DATE_TIME_FORMAT)
);

const checkAndConvertMomentInstanceToFormattedDate = (item: any, format: string) => {
  if (isMomentInstance(item)) {
    return createLocalDateTimeFromInstanceOrISOString(item, format);
  }

  return item;
};

const checkAndConvertStringToFormattedDate = (item: any, format: string) => {
  if (isString(item)) {
    return createLocalDateTimeFromInstanceOrISOString(item, format);
  }

  return item;
};

const checkAndConvertMomentInstanceOrStringToFormattedDate = (item: any, format: string) => {
  if (R.or(isString(item), isMomentInstance(item))) {
    return createLocalDateTimeFromInstanceOrISOString(item, format);
  }

  return item;
};

const setMaxDate = (props: Object, field: Object) => {
  if (R.and(
    notEquals(field.type, 'calendar'),
    notEquals(field.type, 'datePicker'),
  )) return null;

  if (isNotNilAndNotEmpty(field.maxDate)) return field.maxDate;

  if (R.and(
      isNotNilAndNotEmpty(field.maxDateField),
      isNotNilAndNotEmpty(R.path(['formValues', field.maxDateField], props)),
    )) {
    return makeMomentInstance(props.formValues[field.maxDateField]);
  }

  return null;
};

const setMinDate = (props: Object, field: Object) => {
  if (R.and(
    notEquals(field.type, 'calendar'),
    notEquals(field.type, 'datePicker'),
  )) return null;

  if (isNotNilAndNotEmpty(field.minDate)) return field.minDate;

  if (R.and(
      isNotNilAndNotEmpty(field.minDateField),
      isNotNilAndNotEmpty(R.path(['formValues', field.minDateField], props)),
    )) {
    return makeMomentInstance(props.formValues[field.minDateField]);
  }

  return null;
};

const getFormattedDurationFromString = (str: string, humanize: boolean) => {
  const dur = moment.duration(str);

  if (humanize) return dur.humanize();

  return `${dur.days()} days ${dur.hours()} hours ${dur.minutes()} mins`;
};

const getFormattedDurationFromMinutes = (str: string) => {
  const dur = moment.duration(str, 'minutes');

  const days = dur.days();
  const hours = dur.hours();
  const minutes = dur.minutes();

  const dayStr = ifElse(R.equals(days, 0), '', `${days} ${isPlural(days, 'day')} `);
  const hourStr = ifElse(R.equals(hours, 0), '', `${hours} ${isPlural(hours, 'hour')} `);
  const minuteStr = ifElse(R.equals(minutes, 0), '', `${minutes} ${isPlural(minutes, 'min')} `);

  return R.or(`${dayStr}${hourStr}${minuteStr}`, '0 min');
};

const getDurationHumanize = (str: string, humanize: boolean) => {
  const dur = moment.duration(str);

  if (humanize) return dur.humanize();

  const days = dur.days();
  const hours = dur.hours();

  if (R.equals(days, 0)) return `${hours} ${isPlural(hours, 'hour')}`;

  if (R.equals(hours, 0)) return `${days} ${isPlural(days, 'day')}`;

  return `${days} ${isPlural(days, 'day')}  ${hours} ${isPlural(hours, 'hour')}`;
};

const getDateTimeFormat = (timeSelection: boolean) => (
  ifElse(
    R.equals(timeSelection, true),
    GC.DEFAULT_DATE_TIME_FORMAT,
    GC.DEFAULT_DATE_FORMAT,
  )
);

const getDateTimeFormatFromConfig = (formatType: string = 'dateTime') => {
  const dateTimeFormatConfig = getAmousConfigByNameFromWindow(GC.GENERAL_BRANCH_DATE_TIME_FORMAT);

  const formatDataObject = R.pathOr(
    R.prop(GC.DATE_TIME_FORMAT_US, GC.dateTimeFormatMap),
    [dateTimeFormatConfig],
    GC.dateTimeFormatMap,
  );

  return R.path([formatType, 'format'], formatDataObject);
};

const getDateTimeFormatForMui = (timeSelection: boolean) => {
  const timeFormat = getAmousConfigByNameFromWindow(GC.GENERAL_BRANCH_DATE_TIME_FORMAT);
  const ampm = R.equals(timeFormat, GC.DATE_TIME_FORMAT_US);
  const format = ifElse(
    R.equals(timeSelection, true),
    ifElse(
      ampm,
      GC.DATE_TIME_FORMAT_AM_PM_UPPERCASE,
      GC.DEFAULT_US_MILITARY_DATE_TIME_FORMAT,
    ),
    GC.DEFAULT_DATE_FORMAT,
  );

  return {
    ampm,
    format,
  };
};

const getSplittedDateTime = (value: any) => {
  if (R.and(isNotNilAndNotEmpty(value), isValidMoment(value))) {
    const splitted = R.split(' ', value);
    const date = R.head(splitted);
    const time = R.nth(1, splitted);
    const ampm = R.nth(2, splitted);

    return { date, time, ampm };
  }

  return value;
};

const setTimeToDateTime = (value: any, time: string) => {
  if (isAllTrue(isNotNilAndNotEmpty(value), isNotNilAndNotEmpty(time), isValidMoment(value))) {
    const splitted = R.split(' ', value);
    const date = R.head(splitted);

    const newValue = R.toUpper(`${date} ${time}`);

    if (isValidMoment(newValue)) return newValue;

    return value;
  }

  return value;
};

const convertTimeToFormat = (timeString: string, format: string = GC.DEFAULT_TIME_FORMAT) => {
  if (isNilOrEmpty(timeString)) return timeString;

  return checkAndConvertStringToFormattedDate(
    `${moment().format(GC.DEFAULT_DATE_FORMAT)} ${timeString}`,
    format,
  );
};

const getFormattedOperationHoursFromStop = ({ operationHour }: Object) => {
  if (isNilOrEmpty(operationHour)) return operationHour;

  return R.map(
    (entity: Object) => R.mergeRight(entity, {
      [GC.FIELD_TIME_TO]: convertTimeToFormat(
        R.path([GC.FIELD_TIME_TO], entity),
        GC.DEFAULT_TIME_FORMAT,
      ),
      [GC.FIELD_TIME_FROM]: convertTimeToFormat(
        R.path([GC.FIELD_TIME_FROM], entity),
        GC.DEFAULT_TIME_FORMAT,
      ),
    }),
    operationHour,
  );
};

const convertTimeToConfigFormat = (time: string) => {
  const format = getDateTimeFormatFromConfig('time');

  return convertTimeToFormat(time, format);
};

const convertDateTimeToConfigFormat = (dateTime: string) => {
  const format = getDateTimeFormatFromConfig();

  return checkAndConvertMomentInstanceOrStringToFormattedDate(dateTime, format);
};

const convertOperationHoursToConfigFormat = R.map((entity: Object) => ({
  ...entity,
  [GC.FIELD_TIME_TO]: convertTimeToConfigFormat(R.prop(GC.FIELD_TIME_TO, entity)),
  [GC.FIELD_TIME_FROM]: convertTimeToConfigFormat(R.prop(GC.FIELD_TIME_FROM, entity)),
}));

const convertOperationHoursToDefaultFormat = R.map((entity: Object) => ({
  ...entity,
  [GC.FIELD_TIME_TO]: convertTimeToFormat(R.prop(GC.FIELD_TIME_TO, entity)),
  [GC.FIELD_TIME_FROM]: convertTimeToFormat(R.prop(GC.FIELD_TIME_FROM, entity)),
}));

const convertMinutesToHoursAndMinutes = (min: number) => {
  const defaultObject = {
    hours: 0,
    minutes: 0,
    shortString: '0h 0m',
    longString: '0 hour(s) 0 minute(s)',
  };

  if (isNilOrZero(min)) return defaultObject;

  const minNumber = toNumber(min);

  if (R.not(isNumber(minNumber))) return defaultObject;

  const hours = R.divide(minNumber, 60);
  const rHours = Math.floor(hours);
  const minutes = R.multiply(R.subtract(hours, rHours), 60);
  const rMinutes = Math.round(minutes);

  return {
    hours: rHours,
    minutes: rMinutes,
    shortString: `${rHours}h ${rMinutes}m`,
    longString: `${rHours} hour(s) ${rMinutes} minute(s)`,
  };
};

const getModuloFromHours = (hours: number, rHours: number) => {
  if (R.lt(hours, 1)) return hours;

  return R.modulo(hours, rHours);
};

const convertHoursToHoursAndMinutes = (h: any) => {
  const defaultObject = {
    hours: 0,
    minutes: 0,
    shortString: '0h 0m',
    longString: '0 hour(s) 0 minute(s)',
  };

  if (isNilOrZero(h)) return defaultObject;

  const hNumber = toNumber(h);

  if (R.not(isNumber(hNumber))) return defaultObject;


  const rHours = Math.floor(hNumber);
  const modulo = getModuloFromHours(hNumber, rHours);
  const minutes = R.multiply(modulo, 60);
  const rMinutes = Math.ceil(minutes);

  return {
    hours: rHours,
    minutes: rMinutes,
    shortString: `${rHours}h ${rMinutes}m`,
    longString: `${rHours} hour(s) ${rMinutes} minute(s)`,
  };
};

const convertDateTimeToConfigTimeZone = (dateTime: string, showTimeZoneName: boolean = true) => {
  if (isNilOrEmpty(dateTime)) return dateTime;

  const twoDigitFormat = '2-digit';
  const timeZone = getAmousConfigByNameFromWindow(GC.GENERAL_BRANCH_TIMEZONE);

  return getNewDate(dateTime).toLocaleString('en-US', {
    timeZone,
    year: 'numeric',
    day: twoDigitFormat,
    hour: twoDigitFormat,
    month: twoDigitFormat,
    minute: twoDigitFormat,
    timeZoneName: ifElse(showTimeZoneName, 'short', undefined),
  });
};

const formatStopDateTimeValues = (stop: Object) => R.mergeRight(stop, {
  [GC.FIELD_LOAD_EVENT_LATE_TIME]: convertTimeToFormat(
    R.path([GC.FIELD_LOAD_EVENT_LATE_TIME], stop),
    GC.DEFAULT_TIME_FORMAT,
  ),
  [GC.FIELD_LOAD_EVENT_LATE_DATE]: checkAndConvertStringToFormattedDate(
    R.path([GC.FIELD_LOAD_EVENT_LATE_DATE], stop),
    GC.DEFAULT_DATE_TIME_FORMAT,
  ),
  [GC.FIELD_LOAD_EVENT_EARLY_TIME]: convertTimeToFormat(
    R.path([GC.FIELD_LOAD_EVENT_EARLY_TIME], stop),
    GC.DEFAULT_TIME_FORMAT,
  ),
  [GC.FIELD_LOAD_EVENT_EARLY_DATE]: checkAndConvertStringToFormattedDate(
    R.path([GC.FIELD_LOAD_EVENT_EARLY_DATE], stop),
    GC.DEFAULT_DATE_TIME_FORMAT,
  ),
  [GC.FIELD_LOAD_APPOINTMENT_DATE]: checkAndConvertStringToFormattedDate(
    R.path([GC.FIELD_LOAD_APPOINTMENT_DATE], stop),
    GC.DEFAULT_DATE_FORMAT,
  ),
  [GC.FIELD_LOAD_APPOINTMENT_LATE_TIME]: convertTimeToFormat(
    R.path([GC.FIELD_LOAD_APPOINTMENT_LATE_TIME], stop),
    GC.DEFAULT_TIME_FORMAT,
  ),
  [GC.FIELD_LOAD_APPOINTMENT_EARLY_TIME]: convertTimeToFormat(
    R.path([GC.FIELD_LOAD_APPOINTMENT_EARLY_TIME], stop),
    GC.DEFAULT_TIME_FORMAT,
  ),
  [GC.FIELD_OPERATION_HOUR]: getFormattedOperationHoursFromStop(stop),
  [GC.FIELD_EARLY_DATE]: checkAndConvertStringToFormattedDate(
    R.path([GC.FIELD_EARLY_DATE], stop),
    GC.DEFAULT_DATE_TIME_FORMAT,
  ),
  [GC.FIELD_LATE_DATE]: checkAndConvertStringToFormattedDate(
    R.path([GC.FIELD_LATE_DATE], stop),
    GC.DEFAULT_DATE_TIME_FORMAT,
  ),
});

const getValuesWithConvertedDateTimeFields = ({ values, dateTimeFields }: Object) => {
  if (isNilOrEmpty(dateTimeFields)) return values;

  const getConvertedDate = (date: string) => {
    if (isValidMoment(date)) return convertInstanceToDefaultDateTimeFormat(date);

    return date;
  };

  let valuesWithConvertedDateTimeFields = values;

  dateTimeFields.forEach((field: string) => {
    valuesWithConvertedDateTimeFields = R.assoc(
      field,
      getConvertedDate(R.pathOr('', [field], values)),
      valuesWithConvertedDateTimeFields,
    );
  });

  return valuesWithConvertedDateTimeFields;
};

const getDayOfThisWeekByDay = (day: string) => moment().isoWeekday(GC.dayToIsoDayNumberMap[day]).toDate();

const getDayOfPreviousWeekByDay = (day: string) => moment().subtract(1, 'weeks').isoWeekday(GC.dayToIsoDayNumberMap[day]).toDate();

const getDayOfNextWeekByDay = (day: string) => moment().add(1, 'weeks').isoWeekday(GC.momentDayNumberMap[day]).toDate();

const getLocalTimeFromDate = (date: string | Object) => createLocalDateTimeFromInstanceOrISOString(date, 'LT');

const getTimeByConfigFormat = (date: string) => {
  const format = getDateTimeFormatFromConfig('time');

  if (isValidMoment(date)) {
    return moment(date).format(format);
  }

  return date;
};

export {
  fromNow,
  isAfter,
  toMidday,
  isBefore,
  isBetween,
  isMidnight,
  setMinDate,
  setMaxDate,
  getNewDate,
  isValidTime,
  isBetweenDay,
  getDateRange,
  isSameMoment,
  getCurrentDay,
  addMomentTime,
  isValidMoment,
  getCurrentDate,
  isMomentInstance,
  getDateTimeFormat,
  setTimeToDateTime,
  subtractMomentTime,
  makeMomentInstance,
  getStartOfMonthDay,
  toEndOfDayFromDate,
  getDurationHumanize,
  getSplittedDateTime,
  convertTimeToFormat,
  getLocalTimeFromDate,
  toStartOfDayFromDate,
  getDayOfThisWeekByDay,
  getCurrentDayWithTime,
  getTimeByConfigFormat,
  getNowTimeMomentObject,
  addMomentTimeWithFormat,
  getDateTimeFormatForMui,
  isValidMomentWithFormat,
  getCurrentDateWithFormat,
  formatStopDateTimeValues,
  convertTimeToConfigFormat,
  getDayOfPreviousWeekByDay,
  momentAddYearsFromCurrent,
  createLocalDateTimeString,
  createLocalDateTimeFormat,
  convertInstanceToISOString,
  convertInstanceToLocalTime,
  convertISOStringToLocalTime,
  getDateTimeFormatFromConfig,
  subtractMomentTimeWithFormat,
  convertHoursToHoursAndMinutes,
  convertDateTimeToConfigFormat,
  getFormattedDurationFromString,
  momentSubtractYearsFromCurrent,
  createTodayLocalDateTimeString,
  convertDateTimeToConfigTimeZone,
  convertMinutesToHoursAndMinutes,
  getFormattedDurationFromMinutes,
  convertInstanceToDefaultUserFormat,
  getFormattedOperationHoursFromStop,
  convertInstanceToDefaultDateFormat,
  convertOperationHoursToConfigFormat,
  getValuesWithConvertedDateTimeFields,
  convertOperationHoursToDefaultFormat,
  checkAndConvertStringToFormattedDate,
  convertInstanceToDefaultDateTimeFormat,
  createLocalDateTimeFromInstanceOrISOString,
  checkAndConvertMomentInstanceToFormattedDate,
  checkAndConvertMomentInstanceOrStringToFormattedDate,
};
